<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Products</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="products"
          sort-by="no"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:item.no="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.unitPrice="{ item }">
            {{ formatPrice(item.unitPrice) }}
          </template>
          <template v-slot:body.append="{}">
            <tr>
              <td colspan="6">TOTAL</td>
              <td style="border: 1px">
                {{ formatPrice(total) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-products-detail",
  props: {
    dialog: Boolean,
    products: Array,
    currency: String,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    total() {
      let total = 0;
      this.products.forEach(x => {
        total += x.unitPrice * x.qty;
      });
      return total;
    },
    headers() {
      return [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Mfr",
          value: "mfr",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Part#",
          value: "partNumber",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Part Desc",
          value: "partDesc",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 4,
          text: "Qty",
          value: "qty",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 5,
          text: "U/M",
          value: "unitMeasure",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 6,
          text: `Unit Price (${this.currency})`,
          value: "unitPrice",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
      ];
    },
  },
};
</script>

<style></style>
